const extractSessionToken = (queryString = window.location.search) => {
  const SESSION_TOKEN = 'sessiontoken'
  const sp = new URLSearchParams(queryString)
  if (sp.has(SESSION_TOKEN)) {
    return sp.get(SESSION_TOKEN)
  }
  return void 0
}

window.prioritymatrix = {}

const token = extractSessionToken()

if (token) {
  window.prioritymatrix.sessionToken = token
}

const wrappedFetch = url => {
  const headers = {
    'Content-Type': 'application/json',
  }
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  return fetch(url, {
    credentials: token ? 'omit' : 'same-origin',
    headers,
  })
}

window.prioritymatrix.mePromise = wrappedFetch('/api/v1/me/')
  .then(res => res.json())
  .catch(err => undefined)

window.prioritymatrix.projectSummariesPromise = wrappedFetch(
  '/api/v1/project_summary/?limit=1000&state=0&skip_tag=archived&returnindex=1&returntags=1'
)
  .then(res => res.json())
  .catch(err => undefined)
